import React, { Component } from "react";

export default class Spinner extends Component {
  render() {
    const { show } = this.props;
    return (
        show ? (
          <div className="spinner-box">
            <div className="center-spinner-box">
              <div className="pulse-container">  
                <div className="pulse-bubble pulse-bubble-1"></div>
                <div className="pulse-bubble pulse-bubble-2"></div>
                <div className="pulse-bubble pulse-bubble-3"></div>
            </div>
            </div>
        </div>
        ) : 
        null
      )
  }
}